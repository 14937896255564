import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import getSymbolFromCurrency from "currency-symbol-map";
import Moment from "moment";
import { Box, Flex, Text } from "@100mslive/roomkit-react";
import { SESSION_AUTH } from "../../../../common/auth";
import { SUBSCRIPTION_STATUS_LIST } from "../../../../common/constants";

const AccountBillingPlan = props => {
  const { subscription, billingPlan } = props;
  const { isUserSession } = SESSION_AUTH();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [isEditAccess, setIsEditAccess] = useState(false);
  const [currentBillingPlan, setCurrentBillingPlan] = useState(null);

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  useEffect(() => {
    setIsEditAccess(
      userData?.org?.isOwner || userData?.org?.isAdmin ? true : false
    );
    return () => null;
  }, [userData]);

  useEffect(() => {
    setCurrentBillingPlan(billingPlan);
    return () => null;
  }, [billingPlan]);

  if (!isEditAccess) return <></>;

  return (
    <>
      <div className="events-page-account-setting-content-row">
        <Box className="events-page-account-setting-content-box">
          <div className="events-page-account-setting-content-box-title">
            Monthly Plan
            <div className="events-page-account-setting-content-box-title-tag">
              {(subscription?.status
                ? SUBSCRIPTION_STATUS_LIST[subscription?.status]?.name
                : "") || ""}
            </div>
          </div>
          <div className="events-page-account-setting-content-box-line"></div>

          {currentBillingPlan ? (
            <Box className="events-page-account-setting-content-billing-plan">
              {subscription?.status && subscription?.startDate ? (
                <Flex
                  align="center"
                  className="events-page-account-setting-content-box-row"
                >
                  <Box
                    css={{
                      flex: "1 1 0",
                    }}
                    className="events-page-account-setting-content-box-text"
                  >
                    <Text className="events-page-account-setting-content-billing-plan-text">
                      Current billing cycle (
                      {Moment(subscription?.startDate).format("MMM DD")} –
                      &nbsp;
                      {Moment(subscription?.endDate).format("MMM DD")}).
                    </Text>
                  </Box>
                  <Text
                    css={{
                      flex: "1 1 0",
                    }}
                    className="events-page-account-setting-content-box-text text-right"
                  >
                    {subscription?.label}
                  </Text>
                </Flex>
              ) : null}
              <TableContainer>
                <Table sx={{ minWidth: 550 }} aria-label="members table">
                  <TableHead>
                    <TableRow>
                      <TableCell>ITEM</TableCell>
                      <TableCell>COUNT</TableCell>
                      <TableCell>UNIT PRICE</TableCell>
                      <TableCell align="right">PRICE</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentBillingPlan?.list?.map((item, key) => (
                      <TableRow key={key}>
                        <TableCell>{item?.name}</TableCell>
                        <TableCell>{item?.quantity}</TableCell>
                        <TableCell>
                          <NumericFormat
                            value={item?.price || 0}
                            displayType="text"
                            thousandSeparator={true}
                            prefix={getSymbolFromCurrency(item?.currency)}
                            renderText={(value, props) => (
                              <span {...props}>{value}</span>
                            )}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <NumericFormat
                            value={item?.subTotal || 0}
                            displayType="text"
                            thousandSeparator={true}
                            prefix={getSymbolFromCurrency(item?.currency)}
                            renderText={(value, props) => (
                              <span {...props}>{value}</span>
                            )}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell>Total</TableCell>

                      <TableCell align="right" colSpan={3}>
                        <NumericFormat
                          value={currentBillingPlan?.total?.price || 0}
                          displayType="text"
                          thousandSeparator={true}
                          prefix={getSymbolFromCurrency(
                            currentBillingPlan?.total?.currency
                          )}
                          renderText={(value, props) => (
                            <span {...props}>{value}</span>
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Box>
          ) : null}

          <div className="events-page-account-setting-content-box-line"></div>
          <Flex
            align="center"
            className="events-page-account-setting-content-box-row"
          >
            <Box
              css={{
                flex: "1 1 0",
              }}
              className="events-page-account-setting-content-box-text"
            >
              &nbsp;
            </Box>

            <Text
              css={{
                flex: "1 1 0",
              }}
              className="events-page-account-setting-content-box-text text-right"
            >
              Questions? Send an email to{" "}
              <a
                href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
                className="text-primary"
              >
                {process.env.REACT_APP_SUPPORT_EMAIL}
              </a>
            </Text>
          </Flex>
        </Box>
      </div>
    </>
  );
};

export default AccountBillingPlan;
