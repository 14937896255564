import React from "react";
import imgMission from "../../../assets/images/six/career-video.webp";

const SixCareersVideo = () => {
  return (
    <section className="events-page-landing-section">
      <div className="events6ix-layout-page-middle text-center">
            <img src={imgMission} alt="" />
      </div>
    </section>
  );
};

export default SixCareersVideo;
