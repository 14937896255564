import React from "react";
import { useMedia } from "react-use";
import { config as cssConfig, Flex } from "@100mslive/roomkit-react";
import EventFooterMenuCMS from "./EventFooterMenuCMS";
import EventFooterAbout6ix from "./EventFooterAbout6ix";
import EventFooterContact from "./EventFooterContact";

const EventFooterContentLeft = () => {
  const isMobile = useMedia(cssConfig.media.lg);
  return (
    <Flex className="events6ix-layout-footer-content-left">
      
      <Flex className="events6ix-layout-footer-content-left-row">
        <EventFooterAbout6ix/>
        <EventFooterContact/>
      </Flex>

      {!isMobile ? <EventFooterMenuCMS /> : null}
    </Flex>
  );
};

export default EventFooterContentLeft;
