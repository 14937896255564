import axios from "axios";
import { GET_USER_AUTH_TOKEN } from "../common/auth";
import { API_URL } from "../common/constants";

/**
 * @param {RequestInfo} url
 * @param {RequestInit} options
 * @returns {Promise<Response>}
 */

export function apiCall(url, data) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: GET_USER_AUTH_TOKEN(),
  };
  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + url, data, {
        headers: headers,
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function apiCallMultipart(url, data, onUploadProgress) {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: GET_USER_AUTH_TOKEN(),
  };

  return new Promise((resolve, reject) => {
    axios
      .post(API_URL + url, data, {
        headers: headers,
        onUploadProgress,
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export async function fetchStream(url, data, signal = null) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: GET_USER_AUTH_TOKEN(),
  };

  const response = await fetch(API_URL + url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
    signal: signal,
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  // Check the response type

  if (!response.body) {
    throw new Error("No stream found in response");
  }

  const reader = response.body.getReader();
  const decoder = new TextDecoder();

  return { reader, decoder };
}
