import { apiCall, apiCallMultipart } from "./servicesCall";

export const USER_SERVICES = {
  loginUser(data) {
    return apiCall("/user/login", data);
  },
  loginEmail(data) {
    return apiCall("/user/login/email", data);
  },
  logoutUser(data) {
    return apiCall("/user/logout", data);
  },
  switchAccount(data) {
    return apiCall("/user/switch/account", data);
  },
  getUser(data) {
    return apiCall("/user/current", data);
  },
  getProfile(data) {
    return apiCall("/user/profile", data);
  },
  updateProfile(data) {
    return apiCallMultipart("/user/profile/update", data);
  },
  updatePassword(data) {
    return apiCallMultipart("/user/password/update", data);
  },
  removePassword(data) {
    return apiCallMultipart("/user/password/remove", data);
  },
  speakers(data) {
    return apiCall("/user/speakers", data);
  },
  speakersUploadMedia(data) {
    return apiCallMultipart("/user/speakers/media/upload", data);
  },
  verifyPhone(data) {
    return apiCallMultipart("/user/verify/phone", data);
  },
  verifyPhoneOTP(data) {
    return apiCallMultipart("/user/verify/phone/otp", data);
  },
  analytics(data) {
    return apiCallMultipart("/user/analytics", data);
  },
  watchlistAction(data) {
    return apiCallMultipart("/user/watchlist/action", data);
  },
};
