import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OnBoarding from "../components/Account/OnBoarding";
import SEO from "../components/Common/SEO";
import { useGlobalValue } from "../GlobalContext";
import { SESSION_AUTH } from "../common/auth";

const OnBoardingPage = () => {
  const { isUserSession } = SESSION_AUTH();
  const navigate = useNavigate();
  const { setLoginUserVerify } = useGlobalValue();

  useEffect(() => {
    if (!isUserSession) {
      return navigate("/");
    } else if (!isUserSession?.isVerify) {
      setLoginUserVerify(true);
      return navigate("/");
    } else if (!isUserSession?.org?.isOwner && !isUserSession?.org?.isAdmin) {
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate, setLoginUserVerify]);
  return (
    <React.Fragment>
      <SEO title="On-Boarding | 6ix Events" />
      <div className="events-page-onboarding events6ix-layout-page">
        <div className="events6ix-layout-page-content events-page-onboarding-content">
          <div className="events6ix-layout-page-middle">
            <OnBoarding />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OnBoardingPage;
