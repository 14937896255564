import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import FullPageProgress from "../../components/FullPageProgress";
import { Cross, LogoIcon, SearchIcon } from "../../icons";
import { ToastManager } from "../Toast/ToastManager";
import { COMMUNITY_SERVICES } from "../../services";

const LaningCommunity = () => {
  const [listData, setListData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [textSearch, setTextSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (textSearch) {
      setLoading(true);
      COMMUNITY_SERVICES.search({ search: textSearch })
        .then(data => {
          if (data.code === 200) {
            setSearchData(textSearch ? data.data?.list ?? [] : []);
          }
        })
        .catch(err => {
          ToastManager.addToast({ title: err.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setSearchData([]);
    }
  }, [textSearch]);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    COMMUNITY_SERVICES.list()
      .then(data => {
        if (data.code === 200) {
          if (data.data?.list) {
            setListData(data.data?.list);
          }
        }
      })
      .catch(err => {
        ToastManager.addToast({ title: err.message });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <section className="events-page-landing-section events-page-landing-community">
      <div className="events6ix-layout-page-middle">
        <div className="events-page-landing-section-header text-center">
          <h2 className="events-page-landing-section-header-heading">
            Join an investor community
          </h2>
          <div className="events-page-landing-section-header-text fw-500 text-white">
            or <span className="text-primary ">create your own</span>
          </div>

          <div className="event-page-explore-filter-search events-page-landing-community-search">
            <span className="event-page-explore-filter-search-icon">
              <SearchIcon />
            </span>
            <input
              className="event-page-explore-filter-search-input"
              type="search"
              value={textSearch}
              onChange={e => {
                setTextSearch(e.target.value);
              }}
              placeholder="Search for any company"
            />
            {textSearch && (
              <>
                <button
                  onClick={() => {
                    setTextSearch("");
                  }}
                  className="event-page-explore-filter-search-btn"
                  type="button"
                >
                  <Cross />
                </button>

                <div className="event-page-explore-filter-search-result">
                  {searchData?.length > 0 ? (
                    searchData.map((item, index) => (
                      <Link
                        key={index}
                        to={`/company/${item?.orgSlug}`}
                        className="event-page-explore-filter-search-result-item"
                      >
                        {item?.orgName}
                      </Link>
                    ))
                  ) : (
                    <div className="event-page-explore-filter-search-result-noItem">
                      {loading ? (
                        <CircularProgress />
                      ) : (
                        <>No search result found!</>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>

        <div className="events-page-landing-section-content">
          {listData?.length > 0 ? (
            <div className="events-page-landing-community-row">
              {listData.map((item, index) => (
                <Link
                  to={`/company/${item?.orgSlug}`}
                  className="events-page-landing-community-row-item"
                  key={index}
                  target="_blank"
                >
                  <div className="events-page-landing-community-row-item-inner">
                    <div
                      className="events-page-landing-community-row-item-logo"
                      style={{
                        backgroundColor: item?.orgLogo
                          ? item?.orgLogoColor
                          : "",
                      }}
                    >
                      {item?.orgLogo ? (
                        <img src={item.orgLogo} alt="logo" />
                      ) : (
                        <LogoIcon />
                      )}
                    </div>
                    <div className="events-page-landing-community-row-item-name">
                      {item?.orgName}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          ) : loading ? (
            <FullPageProgress />
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default LaningCommunity;
