import React,{useState} from "react";
import { Button, FormControl, TextareaAutosize } from "@mui/material";
import { Input } from "@100mslive/roomkit-react";
import { ToastManager } from "../../Toast/ToastManager";
import { OTHER_SERVICES } from "../../../services";
import { isValidEmail } from "../../../common/utils";
import FullPageProgress from "../../FullPageProgress";

const EventFooterContact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  
  const saveForm = async () => {
    if (!name) {
      ToastManager.addToast({ title: "Please fill your name" });
      return false;
    } else if (!email || !isValidEmail(email)) {
      ToastManager.addToast({ title: "Please fill email" });
      return false;
    } else if (!phone) {
        ToastManager.addToast({ title: "Please fill phone" });
        return false;
      }  else if (!message) {
      ToastManager.addToast({ title: "Please fill message" });
      return false;
    }
    
    setLoading(true);

    try {
      const data = await OTHER_SERVICES.contact({
        name: name,
        email: email,
        phone: phone,
        message: message,
      });
      setLoading(false);
      if (data.code === 200) {
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        ToastManager.addToast({ title: "Your message sent" });
      } else {
        ToastManager.addToast({ title: data?.message });
      }
    } catch (e) {
      ToastManager.addToast({ title: "Error Something went wrong" });
    }
    setLoading(false);
  };
  return (
       <>
        {loading ? (
       
          <FullPageProgress fixed={true} />
      
      ) : null}
      
      <div className="events6ix-layout-footer-content-left-row-contact">
      <div className="events6ix-layout-footer-content-left-row-contact-inner">
        <div className="events6ix-layout-footer-content-left-row-contact-heading">
            GET IN TOUCH
        </div>

        <div className="events6ix-layout-footer-content-left-row-contact-box">
            <FormControl className="custom-form-group">
                <label htmlFor="name" className="custom-form-label big-label">
                  Full Name
                </label>
                <Input
                  id="name"
                  name="name"
                  className="custom-form-input"
                  value={name}
                  onChange={e =>
                    setName(e.target?.value?.replace(/(<([^>]+)>)/gi, ""))
                  }
                />
              </FormControl>

              <FormControl className="custom-form-group">
                <label htmlFor="email" className="custom-form-label big-label">
                  Email
                </label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  className="custom-form-input"
                  value={email}
                  onChange={e =>
                    setEmail(e.target?.value?.replace(/(<([^>]+)>)/gi, ""))
                  }
                />
              </FormControl>

              <FormControl className="custom-form-group">
                <label htmlFor="phone" className="custom-form-label big-label">
                  Phone
                </label>
                <Input
                  id="phone"
                  name="phone"
                  type="tel"
                  className="custom-form-input"
                  value={phone}
                  onChange={e =>
                    setPhone(e.target?.value?.replace(/(<([^>]+)>)/gi, ""))
                  }
                />
              </FormControl>

              <FormControl className="custom-form-group">
                <label
                  htmlFor="message"
                  className="custom-form-label big-label"
                >
                  Message
                </label>
                <TextareaAutosize
                  id="user-bio"
                  name="bio"
                  value={message}
                  onChange={e =>
                    setMessage(e.target?.value?.replace(/(<([^>]+)>)/gi, ""))
                  }
                  className="custom-form-input"
                  minRows={4}
                  maxRows={4}
                />
              </FormControl>

              <Button
                color="primary"
                variant="outlined"
                className="events6ix-btn events6ix-btn-primary w-100"
                onClick={saveForm}
              >
                Send Message
              </Button>
        </div> 
        </div>
      </div> 
      </>
  );
};

export default EventFooterContact;
