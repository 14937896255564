import React from "react";
import SEO from "../../components/Common/SEO";
import EventFooter from "../../components/Event/EventFooter";
import LaningCommunity from "../../components/Landing/LaningCommunity";
import "./landing.scss";

const HomePage = () => {
  return (
    <React.Fragment>
      <SEO title="Home | 6ix Events" />

      <main className="events-page-landing events6ix-layout-page" role="main">
        <div className="events6ix-layout-page-content events6ix-layout-page-content-space">
          <LaningCommunity />
        </div>
      </main>
      <EventFooter />
    </React.Fragment>
  );
};

export default HomePage;
