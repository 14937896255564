import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { TextareaAutosize } from "@mui/material";
import Moment from "moment";
import remarkGfm from "remark-gfm";
import { Flex } from "@100mslive/roomkit-react";
import { LogoIcon, SendIcon, UserIcon } from "../../icons";
import SEO from "../Common/SEO";
import { ToastManager } from "../Toast/ToastManager";
import { SESSION_AUTH } from "../../common/auth";
import { AI_SERVICES } from "../../services";

const CompanyAIAssistant = props => {
  const { orgLogo, orgLogoColor, orgName, handleLogin } = props;
  const { isUserSession } = SESSION_AUTH();
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");

  const [messageList, setMessageList] = useState([
    {
      role: "ai",
      text: `Hi, I’m ${orgName} AI bot. Ask me a question and I can give you
          an answer.`,
      date: new Date(),
    },
  ]);
  const suggestionList = [
    "How is the company performing?",
    "What are the upcoming catalysts?",
    "What is the investment thesis?",
    "Who are the other investors?",
  ];

  useEffect(() => {
    if (isUserSession && query) {
      sendQuery();
    }
  }, [isUserSession]);
  const sendQuery = async text => {
    let q = text || query;
    if (!q) return;
    if (!isUserSession) {
      return handleLogin();
    }

    setLoading(true);
    setQuery("");
    setMessageList(old => [
      ...old,
      { text: q, role: "user", date: new Date() },
    ]);

    const newController = new AbortController();
    const signal = newController.signal;

    try {
      const { reader, decoder } = await AI_SERVICES.companyChat(
        {
          company: orgName,
          query: q,
        },
        signal
      );

      if (!reader || !decoder) {
        throw new Error("No stream found in response");
      }

      let result = "";
      let done = false;

      setMessageList(old => [
        ...old,
        { text: "", role: "ai", date: new Date() },
      ]);

      while (!done) {
        const { value, done: streamDone } = await reader.read();
        done = streamDone;
        result += decoder.decode(value, { stream: true });

        // eslint-disable-next-line no-loop-func
        setMessageList(old => {
          const updatedList = [...old];
          updatedList[updatedList.length - 1]["text"] = result;
          return updatedList;
        });
      }
    } catch (err) {
      ToastManager.addToast({ title: err.message });
    } finally {
      setLoading(false);
    }
  };

  const CompanyAIAssistantmessageAi = (message, key) => {
    return (
      <Flex className="event-page-company-ai-messages-row" type="ai" key={key}>
        <div
          className="event-page-company-ai-messages-row-logo"
          style={{
            backgroundColor: orgLogo ? orgLogoColor : "",
          }}
        >
          {orgLogo ? <img src={orgLogo} alt="logo" /> : <LogoIcon />}
        </div>
        <div className="event-page-company-ai-messages-row-content">
          <div className="event-page-company-ai-messages-row-content-text">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {message?.text}
            </ReactMarkdown>
          </div>
          <div className="event-page-company-ai-messages-row-content-date">
            {Moment(message?.date).format("hh:mm A")}
          </div>
        </div>
      </Flex>
    );
  };
  const CompanyAIAssistantmessageUser = (message, key) => {
    return (
      <Flex
        className="event-page-company-ai-messages-row"
        type="user"
        key={key}
      >
        <div className="event-page-company-ai-messages-row-content">
          <div className="event-page-company-ai-messages-row-content-text">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {message?.text}
            </ReactMarkdown>
          </div>
          <div className="event-page-company-ai-messages-row-content-date">
            {Moment(message?.date).format("hh:mm A")}
          </div>
        </div>
        <div className="event-page-company-ai-messages-row-logo">
          {isUserSession?.profileImage ? (
            <img src={isUserSession?.profileImage} alt="user" />
          ) : (
            <UserIcon />
          )}
        </div>
      </Flex>
    );
  };

  const CompanyAIAssistantmessageSearch = () => {
    return (
      <Flex className="event-page-company-ai-bottom-search">
        {loading ? (
          <div className="event-page-company-ai-bottom-search-loading">
            AI is thinking{" "}
          </div>
        ) : (
          <>
            <TextareaAutosize
              tabIndex={-1}
              maxRows={2}
              className="event-page-company-ai-bottom-search-input"
              placeholder="Your message..."
              value={query}
              readOnly={loading}
              disabled={loading}
              onChange={e => setQuery(e.target.value)}
              onKeyDown={e => e.key === "Enter" && !e.shiftKey && sendQuery()}
            />
            <div
              onClick={() => sendQuery()}
              role="button"
              tabIndex={-1}
              className="event-page-company-ai-bottom-search-btn"
            >
              <SendIcon />
            </div>
          </>
        )}
      </Flex>
    );
  };

  const CompanyAIAssistantSuggestion = () => {
    return (
      <Flex className="event-page-company-ai-messages-suggestion">
        {suggestionList.map((item, key) => (
          <div
            key={key}
            className="event-page-company-ai-messages-suggestion-col"
          >
            <div
              className="event-page-company-ai-messages-suggestion-col-text"
              onClick={() => {
                sendQuery(item);
              }}
            >
              {item}
            </div>
          </div>
        ))}
      </Flex>
    );
  };
  return (
    <React.Fragment>
      <SEO title={`${orgName || "Profile"} | AI Assistant`} />

      <Flex className="event-page-company-ai">
        <Flex className="event-page-company-ai-messages">
          {messageList.map((item, key) =>
            item?.role == "user"
              ? CompanyAIAssistantmessageUser(item, key)
              : CompanyAIAssistantmessageAi(item, key)
          )}

          {messageList?.length <= 1 ? CompanyAIAssistantSuggestion() : null}
        </Flex>
        <Flex className="event-page-company-ai-bottom">
          {CompanyAIAssistantmessageSearch()}
        </Flex>
      </Flex>
    </React.Fragment>
  );
};

export default CompanyAIAssistant;
