import { apiCall } from "./servicesCall";

export const COMMUNITY_SERVICES = {
  list(data) {
    return apiCall("/community/investors", data);
  },
  search(data) {
    return apiCall("/community/search", data);
  },
};
