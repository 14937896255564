import React from "react";
import Company from "../components/Company";
import EventFooter from "../components/Event/EventFooter";
const CompanyPage = () => {
  return (
    <React.Fragment>
      <main className="events-page-company events6ix-layout-page" role="main">
        <div className="events6ix-layout-page-content events6ix-layout-page-content-space">
          <Company />
        </div>
      </main>
      <EventFooter />
    </React.Fragment>
  );
};

export default CompanyPage;
