import React from "react";
import { useMedia } from "react-use";
import { Add as AddIcon, Done as DoneIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import { config as cssConfig, Flex } from "@100mslive/roomkit-react";
import { LogoIcon, VerifedIcon } from "../../icons";
import { SESSION_AUTH } from "../../common/auth";

const CompanyBanner = props => {
  const {
    orgName,
    orgLogo,
    orgLogoColor,
    isVerified,
    isWatching,
    handleWatching,
    handleLogin,
  } = props;
  const { isUserSession } = SESSION_AUTH();
  const isMobile = useMedia(cssConfig.media.sm);

  return (
    <React.Fragment>
      <Flex className="event-page-company-banner">
        <Flex className="event-page-company-banner-content">
          <div
            className="event-page-company-banner-logo"
            style={{
              backgroundColor: orgLogo ? orgLogoColor : "",
            }}
          >
            {orgLogo ? <img src={orgLogo} alt="logo" /> : <LogoIcon />}
          </div>
          <div className="event-page-company-banner-info">
            <Flex className="event-page-company-banner-info-name">
              {orgName} {isVerified ? <VerifedIcon /> : <></>}
            </Flex>
          </div>
        </Flex>
        <Flex className="event-page-company-banner-action">
          {isUserSession ? (
            isWatching ? (
              <Button
                size={isMobile ? "small" : ""}
                variant="outlined"
                className="events6ix-btn events6ix-btn-light events6ix-btn-sm"
                type="button"
                sx={{ textTransform: "none" }}
                startIcon={<DoneIcon />}
                onClick={() => {
                  handleWatching("remove");
                }}
              >
                Watching
              </Button>
            ) : (
              <Button
                size={isMobile ? "small" : ""}
                variant="outlined"
                className="events6ix-btn events6ix-btn-light events6ix-btn-sm"
                type="button"
                sx={{ textTransform: "none" }}
                startIcon={<AddIcon />}
                onClick={() => {
                  handleWatching("add");
                }}
              >
                Add to Watch List
              </Button>
            )
          ) : (
            <Button
              size={isMobile ? "small" : ""}
              variant="outlined"
              className="events6ix-btn events6ix-btn-light events6ix-btn-sm"
              type="button"
              sx={{ textTransform: "none" }}
              startIcon={<AddIcon />}
              onClick={handleLogin}
            >
              Add to Watch List
            </Button>
          )}
        </Flex>
      </Flex>
    </React.Fragment>
  );
};

export default CompanyBanner;
