import { apiCall, apiCallMultipart } from "./servicesCall";

export const ACCOUNT_SERVICES = {
  getSettingAccounts(data) {
    return apiCall("/account/setting/accounts", data);
  },
  disconnectSettingAccounts(data) {
    return apiCall("/account/setting/disconnect", data);
  },
  resetSetting(data) {
    return apiCall("/account/setting/reset", data);
  },
  updateSetting(data) {
    return apiCallMultipart("/account/setting/update", data);
  },
  getSetting(data) {
    return apiCall("/account/setting", data);
  },
  addMember(data) {
    return apiCall("/account/member/add", data);
  },
  getMembers(data) {
    return apiCall("/account/member/list", data);
  },
  updateMemberRole(data) {
    return apiCall("/account/member/role/update", data);
  },
  memberManagement(data) {
    return apiCall("/account/member/management/update", data);
  },
  removeMemberAccess(data) {
    return apiCall("/account/member/remove", data);
  },
  leaveTeam(data) {
    return apiCall("/account/member/leave", data);
  },
  getList(data) {
    return apiCall("/account/list", data);
  },
  create(data) {
    return apiCall("/account/create", data);
  },
  memberProfile(data) {
    return apiCall("/account/member/profile", data);
  },
  memberProfileUpdate(data) {
    return apiCallMultipart("/account/member/profile/update", data);
  },
  getBilling(data) {
    return apiCall("/account/billing", data);
  },
  billingAddressUpdate(data) {
    return apiCall("/account/billing/address/update", data);
  },

  paymentMethodList(data) {
    return apiCall("/account/billing/payment/method/list", data);
  },

  paymentMethodAdd(data) {
    return apiCall("/account/billing/payment/method/add", data);
  },
  paymentMethodDefault(data) {
    return apiCall("/account/billing/payment/method/default", data);
  },
  paymentMethodRemove(data) {
    return apiCall("/account/billing/payment/method/remove", data);
  },
  createSubscription(data) {
    return apiCall("/account/subcription/create", data);
  },
  getTransactions(data) {
    return apiCall("/account/billing/transactions", data);
  },
  onBoarding(data) {
    return apiCall("/account/on-boarding", data);
  },
  onBoardingSave(data) {
    return apiCall("/account/on-boarding/save", data);
  },
  onBoardingTeam(data) {
    return apiCall("/account/on-boarding/team", data);
  },
  widgetSave(data) {
    return apiCall("/account/widget/save", data);
  },
};
