import React, { useEffect, useState } from "react";
import { GetCity, GetCountries, GetState } from "react-country-state-city";
import { useNavigate } from "react-router-dom";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import { Box, Flex, Input, Label } from "@100mslive/roomkit-react";
import FullPageProgress from "../../../FullPageProgress";
import { ToastManager } from "../../../Toast/ToastManager";
import { SESSION_AUTH } from "../../../../common/auth";
import { ACCOUNT_SERVICES } from "../../../../services";

const AccountBillingAddress = props => {
  const { billingAddress } = props;
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [isEditAccess, setIsEditAccess] = useState(false);

  const [countriesList, setCountriesList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [cityDisable, setCityDisable] = useState(false);
  const [stateDisable, setStateDisable] = useState(false);

  const [line1, setLine1] = useState(billingAddress?.line1 || "");
  const [line2, setLine2] = useState(billingAddress?.line2 || "");
  const [city, setCity] = useState(billingAddress?.city || "");
  const [state, setState] = useState(billingAddress?.state || "");
  const [country, setCountry] = useState(billingAddress?.country || "CAN");
  const [postal_code, setPostal_code] = useState(
    billingAddress?.postal_code || ""
  );
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  useEffect(() => {
    setIsEditAccess(
      userData?.org?.isOwner || userData?.org?.isAdmin ? true : false
    );
    return () => null;
  }, [userData]);

  useEffect(() => {
    if (!isEditAccess) return;
    GetCountries().then(result => {
      setCountriesList(result);
    });
  }, [isEditAccess]);
  const countryChange = value => {
    setCountry(value);
    setState("");
    setCity("");
    setPostal_code("");
    setStatesList([]);
    setCityList([]);
  };
  const stateChange = value => {
    setState(value);
    setCity("");
    setCityList([]);
  };

  useEffect(() => {
    if (!country || !countriesList?.[0]) return;
    let selected = countriesList.filter(item => {
      return item.iso2 === country;
    });
    if (selected?.[0]) {
      GetState(selected?.[0]?.id).then(result => {
        setStatesList(result);
        setStateDisable(result.length > 0 ? false : true);
        setCityDisable(result.length > 0 ? false : true);
      });
    }
  }, [country, countriesList]);

  useEffect(() => {
    if (!country || !state || !countriesList?.[0] || !statesList?.[0]) return;

    let selectedCountry = countriesList.filter(item => {
      return item.iso2 === country;
    });
    let selectedState = statesList.filter(item => {
      return item.name === state;
    });

    if (selectedCountry?.[0] && selectedState?.[0]) {
      GetCity(selectedCountry?.[0]?.id, selectedState?.[0]?.id).then(result => {
        setCityList(result);
        setCityDisable(result.length > 0 ? false : true);
      });
    }
  }, [country, state, countriesList, statesList]);

  const onSave = () => {
    if (!line1) {
      ToastManager.addToast({ title: "Please enter address" });
      return;
    }
    if (!country) {
      ToastManager.addToast({ title: "Please Select country" });
      return;
    }
    if (!state && !stateDisable) {
      ToastManager.addToast({ title: "Please select state" });
      return;
    }
    if (!city && !cityDisable) {
      ToastManager.addToast({ title: "Please select city" });
      return;
    }
    if (!postal_code) {
      ToastManager.addToast({ title: "Please enter zipcode" });
      return;
    }
    setLoading(true);
    ACCOUNT_SERVICES.billingAddressUpdate({
      userId: userData.userId,
      org: userData?.org?.id,
      billingAddress: {
        line1: line1,
        line2: line2,
        city: city,
        state: state,
        postal_code: postal_code,
        country: country,
      },
    })
      .then(data => {
        if (data.code === 200) {
          ToastManager.addToast({ title: data.message });
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          ToastManager.addToast({ title: data.message });
        }
      })
      .catch(error => {
        ToastManager.addToast({ title: error.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!isEditAccess) return <></>;

  return (
    <>
      {loading && <FullPageProgress fixed={true} />}

      <div className="events-page-account-setting-content-row">
        <Box className="events-page-account-setting-content-box">
          <div className="events-page-account-setting-content-box-title">
            Billing Address
          </div>
          <div className="events-page-account-setting-content-box-line"></div>
          <Flex
            align="center"
            className="events-page-account-setting-content-box-row"
            css={{ gap: "20px" }}
          >
            <FormControl className="events-page-account-setting-content-box-control custom-form-group">
              <Label
                htmlFor="billing-address-1"
                className="events-page-account-setting-content-box-lable"
              >
                Address Line 1
              </Label>
              <Input
                id="billing-address-1"
                name="billingaddress1"
                type="text"
                className="custom-form-input"
                value={line1}
                onChange={e => setLine1(e.target.value)}
              />
            </FormControl>

            <FormControl className="events-page-account-setting-content-box-control custom-form-group">
              <Label
                htmlFor="billing-address-2"
                className="events-page-account-setting-content-box-lable"
              >
                Address Line 2
              </Label>
              <Input
                id="billing-address-2"
                name="billingaddress2"
                type="text"
                className="custom-form-input"
                value={line2}
                onChange={e => setLine2(e.target.value)}
              />
            </FormControl>
          </Flex>

          <Flex
            align="center"
            className="events-page-account-setting-content-box-row"
            css={{ gap: "20px" }}
          >
            <FormControl className="events-page-account-setting-content-box-control custom-form-group">
              <Label
                htmlFor="billing-address-country"
                className="events-page-account-setting-content-box-lable"
              >
                Country
              </Label>

              <Select
                id="billing-address-country"
                name="billingaddresscountry"
                className="custom-select"
                value={country}
                onChange={e => countryChange(e.target.value)}
                displayEmpty
              >
                {countriesList.map((item, key) => (
                  <MenuItem key={key} value={item?.iso2}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {!stateDisable && (
              <FormControl className="events-page-account-setting-content-box-control custom-form-group">
                <Label
                  htmlFor="billing-address-state"
                  className="events-page-account-setting-content-box-lable"
                >
                  State
                </Label>
                <Select
                  id="billing-address-state"
                  name="billingaddressstate"
                  className="custom-select"
                  value={state}
                  onChange={e => stateChange(e.target.value)}
                  displayEmpty
                  renderValue={selected => {
                    if (selected.length === 0) {
                      return <span>Select</span>;
                    }
                    return selected;
                  }}
                >
                  {statesList.map((item, key) => (
                    <MenuItem key={key} value={item?.name}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Flex>
          <Flex
            align="center"
            className="events-page-account-setting-content-box-row"
            css={{ gap: "20px" }}
          >
            {!cityDisable && (
              <FormControl className="events-page-account-setting-content-box-control custom-form-group">
                <Label
                  htmlFor="billing-address-city"
                  className="events-page-account-setting-content-box-lable"
                >
                  City
                </Label>
                <Select
                  id="billing-address-city"
                  name="billingaddresscity"
                  className="custom-select"
                  value={city}
                  onChange={e => setCity(e.target.value)}
                  displayEmpty
                  renderValue={selected => {
                    if (selected.length === 0) {
                      return <span>Select</span>;
                    }
                    return selected;
                  }}
                >
                  {cityList.map((item, key) => (
                    <MenuItem key={key} value={item?.name}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <FormControl className="events-page-account-setting-content-box-control custom-form-group">
              <Label
                htmlFor="billing-address-ipcode"
                className="events-page-account-setting-content-box-lable"
              >
                ZIP / Postal Code
              </Label>
              <Input
                id="billing-address-ipcode"
                name="billingaddressszipcode"
                type="text"
                className="custom-form-input"
                value={postal_code}
                onChange={e => setPostal_code(e.target.value)}
              />
            </FormControl>
          </Flex>
          <div className="events-page-account-setting-content-box-line"></div>
          <Flex
            align="center"
            className="events-page-account-setting-content-box-row"
          >
            <Box
              css={{
                flex: "1 1 0",
              }}
              className="events-page-account-setting-content-box-text"
            >
              &nbsp;
            </Box>

            <Button
              color="primary"
              variant="outlined"
              className="events6ix-btn events6ix-btn-primary"
              type="button"
              onClick={onSave}
            >
              Save
            </Button>
          </Flex>
        </Box>
      </div>
    </>
  );
};

export default AccountBillingAddress;
