import React from "react";
import { useMedia } from "react-use";
import { config as cssConfig } from "@100mslive/roomkit-react";
import imgMission from "../../../assets/images/six/careers-mission.webp";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const SixCareersMission = () => {
  const isMobile = useMedia(cssConfig.media.sm);
  return (
    <section className="events-page-landing-section events-page-landing-section-space events-page-careers-section-mission">
      <div className="events6ix-layout-page-middle">
        <div className="events-page-careers-section-mission-content">
          {isMobile && <img src={imgMission} alt="" />}
          <div class="events-page-careers-section-header-text text-primary fw-700 mt-0 mb-10">CAREERS</div>
          <div className="events-page-careers-section-header">
            <div className="events-page-about-section-header-heading">
              Champion the Mission
            </div>
          </div>
          <div className="events-page-careers-section-header-text">
            Join us on our mission to unlock social mobility on a global scale
            by empowering anyone from anywhere to invest, attract capital, and
            share expertise — all from one stage.
            <br />
            <br /> We're building the central hub for global investment
            activities, connecting investors, companies, and experts through our
            video-first, event-driven platform.
          </div>
          <Button
                variant="outlined"
                to="/explore"
                component={Link}
                color="primary"
                className="events6ix-btn events6ix-btn-primary mt-20"
              >
                VIEW OPENINGS
              </Button>

        </div>
      </div>
    </section>
  );
};

export default SixCareersMission;
