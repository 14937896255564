import React from "react";


import { useMedia } from "react-use";
import { config as cssConfig } from "@100mslive/roomkit-react"; 


const SixCareersJoin = () => {
  const isMobile = useMedia(cssConfig.media.sm);
  return (
    <section className="events-page-landing-section events-page-careers-section-join">
      <div className="events6ix-layout-page-middle">
        <div className="events-page-careers-section-header">
          <div className="events-page-careers-section-header-heading text-center">
            Join our mission of empowering anyone  { !isMobile ? <br/> : ""}  from  anywhere to invest in
            anything.
          </div>
        </div>
      </div>
    </section>
  );
};

export default SixCareersJoin;
