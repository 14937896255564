import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Box, Flex } from "@100mslive/roomkit-react";
import CompanyAbout from "./CompanyAbout";
//import CompanySpeakers from "./CompanySpeakers";
import CompanyAIAssistant from "./CompanyAIAssistant";
import CompanyEvents from "./CompanyEvents";

const ComoanyTabs = props => {
  const { id, orgName, hostedEvent, orgLogo, orgLogoColor, handleLogin } =
    props;
  const { orgSlug, tabType } = useParams();
  const [selectedTab, setSelectedTab] = useState(tabType || "events");
  const [tabList, setTabList] = useState("");

  useEffect(() => {
    setSelectedTab(tabType ? tabType : hostedEvent ? "events" : "assistant");
    let list = {};
    if (hostedEvent || tabType === "events") {
      list["events"] = {
        name: "Events",
        to: "events",
        component: <CompanyEvents orgId={id} orgName={orgName} />,
      };
    }
    // if (hostedEvent || tabType === "speakers") {
    //   list["speakers"] = {
    //     name: "Speakers",
    //     to: "speakers",
    //     component: <CompanySpeakers orgId={id} orgName={orgName} />,
    //   };
    // }
    list["assistant"] = {
      name: "AI Assistant",
      to: "assistant",
      component: (
        <CompanyAIAssistant
          orgId={id}
          orgLogo={orgLogo}
          orgName={orgName}
          orgLogoColor={orgLogoColor}
          handleLogin={handleLogin}
        />
      ),
    };

    list["about"] = {
      name: "About",
      to: "about",
      component: <CompanyAbout orgId={id} orgName={orgName} />,
    };

    setTabList(list);
  }, [tabType, id, hostedEvent, orgName]);
  return (
    tabList?.[selectedTab] && (
      <React.Fragment>
        <Flex className="event-page-company-tabs">
          {Object.keys(tabList).map((item, key) => (
            <Link
              key={key}
              to={`/company/${orgSlug}/${tabList?.[item]?.to || ""}`}
              className={`event-page-company-tabs-link ${
                tabList?.[item]?.to === selectedTab ? "active" : ""
              }`}
            >
              {tabList?.[item]?.name}
            </Link>
          ))}
        </Flex>
        <Box className="event-page-company-content">
          {tabList?.[selectedTab]?.component}
        </Box>
      </React.Fragment>
    )
  );
};

export default ComoanyTabs;
