import { apiCall, fetchStream } from "./servicesCall";

export const AI_SERVICES = {
  textGenerate(data) {
    return apiCall("/ai", data);
  },
  imageGenerate(data) {
    return apiCall("/ai/image", data);
  },
  companyChat(data, signal) {
    return fetchStream("/ai/company/chat", data, signal);
  },
};
