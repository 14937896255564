import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginPopup } from "../components/Account/Login/LoginPopup";
import SEO from "../components/Common/SEO";
import EventCreate from "../components/Event/EventCreate";
import EventEdit from "../components/Event/EventEdit";
import PageHeader from "../components/PageHeader";
import { useGlobalValue } from "../GlobalContext";
import { SESSION_AUTH } from "../common/auth";

export const EventCreatePage = () => {
  const { isUserSession } = SESSION_AUTH();
  const [loginClose, setLoginClose] = useState(false);
  const [enableStep, setEnableStep] = useState(0);

  const navigate = useNavigate();
  const { setLoginUserVerify } = useGlobalValue();

  const handleLogin = () => {
    setTimeout(() => {
      setLoginClose(true);
    }, 100);
  };
  useEffect(() => {
    if (loginClose && !isUserSession) {
      return navigate("/");
    } else if (isUserSession && !isUserSession?.isVerify) {
      setLoginUserVerify(true);
      return navigate("/");
    } else if (
      (isUserSession?.org?.isOwner || isUserSession?.org?.isAdmin) &&
      !isUserSession?.org?.onBoarding
    ) {
      return navigate("/on-boarding?continueUrl=/create");
    } else if (isUserSession?.isVerify) {
      setEnableStep(1);
    } else {
      setEnableStep(2);
    }
    return () => null;
  }, [loginClose, isUserSession, navigate, setLoginUserVerify]);
  return (
    <React.Fragment>
      <SEO title="Event | 6ix Events" />

      <div className="events-page-create events6ix-layout-page">
        <PageHeader type="overview" />
        <div className="events6ix-layout-page-content">
          {isUserSession?.isVerify && enableStep === 1 ? (
            <EventCreate />
          ) : !loginClose && enableStep === 2 ? (
            <LoginPopup cancel={true} handleLogin={handleLogin} />
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};
export const EventEditPage = () => {
  const { isUserSession } = SESSION_AUTH();
  const navigate = useNavigate();
  const { setLoginUserVerify } = useGlobalValue();

  useEffect(() => {
    if (!isUserSession) {
      return navigate("/");
    } else if (!isUserSession?.isVerify) {
      setLoginUserVerify(true);
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate, setLoginUserVerify]);

  return (
    <React.Fragment>
      <SEO title="Edit | 6ix Events" />
      <main className="events-page-create events6ix-layout-page" role="main">
        <PageHeader type="dashboard" />
        <div className="events6ix-layout-page-content">
          <EventEdit />
        </div>
      </main>
    </React.Fragment>
  );
};
