import React from "react";
import { useMedia } from "react-use";
import { config as cssConfig } from "@100mslive/roomkit-react";
import EventFooterMenuCMS from "./EventFooterMenuCMS";

const EventFooterBottom = () => {
  const isMobile = useMedia(cssConfig.media.lg);
  return (
    <div className="events6ix-layout-footer-bottom">
      {isMobile ? <EventFooterMenuCMS /> : null}
      <div className="events6ix-layout-footer-bottom-text">
      JOIN OR CREATE INVESTOR COMMUNITIES
      </div>
      
    </div>
  );
};

export default EventFooterBottom;
