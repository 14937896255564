import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useStripe } from "@stripe/react-stripe-js";
import { Box, Flex, Label, Switch, Text } from "@100mslive/roomkit-react";
import ConfirmDialog from "../../../Common/ConfirmDialog";
import FullPageProgress from "../../../FullPageProgress";
import { ToastManager } from "../../../Toast/ToastManager";
import AccountBillingCardPopup from "./AccountBillingCardPopup";
import { SESSION_AUTH } from "../../../../common/auth";
import { ACCOUNT_SERVICES } from "../../../../services";

const AccountBillingAddons = props => {
  const { subscription, billingAddons } = props;
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [isEditAccess, setIsEditAccess] = useState(false);
  const [currentPlan, setCurrentPlan] = useState("");
  const [selectedNewPlan, setSelectedNewPlan] = useState("");
  const [confirmPlan, setConfirmPlan] = useState(false);
  const [addNewCard, setAddNewCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  useEffect(() => {
    let plan = billingAddons?.bronze;
    if (subscription && subscription?.plan?.product && billingAddons) {
      for (const [key] of Object.entries(billingAddons)) {
        if (billingAddons[key].priceId === subscription?.plan?.priceId) {
          plan = billingAddons[key];
        }
      }
    }
    setCurrentPlan(plan);
    return () => null;
  }, [subscription, billingAddons]);

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  useEffect(() => {
    setIsEditAccess(
      userData?.org?.isOwner || userData?.org?.isAdmin ? true : false
    );
    return () => null;
  }, [userData]);

  const onChoosePlan = item => {
    if (
      item?.priceId !== currentPlan?.priceId ||
      (subscription?.status &&
        subscription?.status !== 1 &&
        item?.priceId === currentPlan?.priceId)
    ) {
      setConfirmPlan(true);
      setSelectedNewPlan(item);
    }
  };

  const onCancelPlan = () => {
    setConfirmPlan(false);
    setSelectedNewPlan("");
  };

  const onCancelNewCard = () => {
    setAddNewCard(false);
  };

  const onAcceptPlan = () => {
    setConfirmPlan(false);

    if (selectedNewPlan?.id) {
      setLoading(true);
      ACCOUNT_SERVICES.createSubscription({
        userId: userData.userId,
        org: userData?.org?.id,
        plan: selectedNewPlan,
      })
        .then(async data => {
          if (data.code === 200) {
            if (data?.data?.clientSecret) {
              const confirmPayment = await stripe?.confirmCardPayment(
                data.data?.clientSecret
              );

              if (confirmPayment?.error) {
                ToastManager.addToast({
                  title: confirmPayment.error.message,
                });
              } else {
                ToastManager.addToast({
                  title: data.message,
                });
              }
            } else {
              ToastManager.addToast({
                title: data.message,
              });
            }
            setTimeout(function () {
              navigate("/R_C_P");
            }, 1000);
          } else if (data.code === 201) {
            setAddNewCard(true);
            setLoading(false);
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
            setLoading(false);
          }
        })
        .catch(error => {
          setLoading(false);
          ToastManager.addToast({ title: error.message });
        });
    }
  };

  const onNewCard = () => {
    setAddNewCard(false);
    onAcceptPlan();
  };

  return (
    <>
      {loading && <FullPageProgress fixed={true} />}
      {isEditAccess && (
        <div className="events-page-account-setting-content-row">
          <Box className="events-page-account-setting-content-box">
            <div className="events-page-account-setting-content-box-title">
              6ix Events Add-Ons
            </div>
            <div className="events-page-account-setting-content-box-line"></div>

            <Box className="events-page-account-setting-content-billing-addons">
              {billingAddons &&
                Object.keys(billingAddons).map((item, index) => (
                  <Box key={index}>
                    <Box
                      className={`events-page-account-setting-content-billing-addons-row ${
                        currentPlan?.priceId === billingAddons[item]?.priceId
                          ? "active"
                          : ""
                      } `}
                      onClick={() => {
                        onChoosePlan(billingAddons[item]);
                      }}
                    >
                      <Text className="events-page-account-setting-content-billing-addons-row-price">
                        {billingAddons[item]?.text}
                      </Text>

                      <Label className="events-page-account-setting-content-billing-addons-row-label">
                        <Switch
                          checked={
                            currentPlan?.priceId ===
                            billingAddons[item]?.priceId
                          }
                        />
                        {billingAddons[item]?.name}
                      </Label>
                      <Text className="events-page-account-setting-content-billing-addons-row-text">
                        {billingAddons[item]?.description}
                      </Text>
                      {currentPlan?.priceId === billingAddons[item]?.priceId &&
                        subscription?.status &&
                        subscription?.status !== 1 && (
                          <Button
                            color="primary"
                            variant="outlined"
                            className="events6ix-btn events6ix-btn-primary mt-10"
                            type="button"
                            onClick={() => {
                              onChoosePlan(billingAddons[item]);
                            }}
                            sx={{ textTransform: "none" }}
                          >
                            Update Plan
                          </Button>
                        )}
                    </Box>
                    <div className="events-page-account-setting-content-box-line"></div>
                  </Box>
                ))}
            </Box>

            <Flex
              align="center"
              className="events-page-account-setting-content-box-row"
            >
              <Box
                css={{
                  flex: "1 1 0",
                }}
                className="events-page-account-setting-content-box-text"
              >
                &nbsp;
              </Box>

              <Text
                css={{
                  flex: "1 1 0",
                }}
                className="events-page-account-setting-content-box-text text-right"
              >
                Questions? Send an email to{" "}
                <a
                  href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
                  className="text-primary"
                >
                  {process.env.REACT_APP_SUPPORT_EMAIL}
                </a>
              </Text>
            </Flex>
          </Box>
        </div>
      )}
      {confirmPlan && selectedNewPlan && (
        <ConfirmDialog
          open={confirmPlan ? true : false}
          data={{
            title: selectedNewPlan?.name,
            text: `By clicking "Confirm," you acknowledge and agree to the changes. If you have any questions, feel free to contact our support team ${process.env.REACT_APP_SUPPORT_EMAIL}`,
            yesLabel: "Confirm",
          }}
          onCancel={onCancelPlan}
          onAccept={onAcceptPlan}
        />
      )}
      {addNewCard && (
        <AccountBillingCardPopup
          onClose={onCancelNewCard}
          onUpdate={onNewCard}
        />
      )}
    </>
  );
};

export default AccountBillingAddons;
