import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, FormControl, TextareaAutosize } from "@mui/material";
import { Input } from "@100mslive/roomkit-react";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import { SESSION_AUTH } from "../../../common/auth";
import { isValidEmail } from "../../../common/utils";
import { OTHER_SERVICES } from "../../../services";
import "./style.scss";

const SixDemoSidebar = () => {
  const location = useLocation();
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [invest, setInvest] = useState(true);
  const [attract, setAttract] = useState(true);
  const [expertise, setExpertise] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEmail(isUserSession?.email || "");
  }, [isUserSession]);

  const demoRequest = () => {
    if (!email || (email && !isValidEmail(email))) {
      ToastManager.addToast({ title: "Enter valid email address" });
      return;
    }
    if (!message) {
      ToastManager.addToast({ title: "Enter your message" });
      return;
    }

    setLoading(true);
    OTHER_SERVICES.demoRequest({
      userId: isUserSession?.userId,
      org: isUserSession?.org?.id,
      email: email,
      message: message,
      invest: invest ? "Yes" : "No",
      attract: attract ? "Yes" : "No",
      expertise: expertise ? "Yes" : "No",
      type:
        location?.pathname === "/request-meeting"
          ? "Meeting"
          : location?.pathname === "/broadcast"
          ? "Broadcast"
          : "Demo",
    })
      .then(data => {
        if (data.code === 200) {
          ToastManager.addToast({ title: data?.message });
          window.location.href =
            "https://meetings.6ix.com/danielbarankin/daniel-barankin-15";
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          ToastManager.addToast({ title: data?.message });
          setLoading(false);
        }
      })
      .catch(err => {
        ToastManager.addToast({ title: err?.message });
        setLoading(false);
      });
  };
  return (
    <React.Fragment>
      {loading && <FullPageProgress fixed={true} />}

      <div className="events-page-demo-section-row-sidebar">
        <div className="events-page-demo-section-row-sidebar-heading">
          Talk to a human
        </div>
        {!isUserSession ? 
        <FormControl className="custom-form-group">
          <label htmlFor="demo-email" className="custom-form-label big-label">
            Email
          </label>
           
            <Input
              id="demo-email"
              name="email"
              placeholder="Enter Your Email"
              className="custom-form-input"
              value={email}
              onChange={e => setEmail(e.target.value)}
              autoComplete="none"
            />
        </FormControl>
        :null}
        <FormControl className="custom-form-group">
          <label htmlFor="demo-message" className="custom-form-label big-label">
            Your Message
          </label>
          <TextareaAutosize
            id="demo-message"
            name="message"
            value={message}
            onChange={e => setMessage(e.target.value)}
            className="custom-form-input"
            placeholder="Enter Message"
            minRows={isUserSession ? 7 : 5}
            maxRows={isUserSession ? 7 : 5}
          />
        </FormControl>
        <FormControl className="custom-form-group">
          <div className="custom-form-checkbox">
            <input
              id="demo-investCapital"
              type="checkbox"
              className="custom-form-checkbox-input"
              onChange={e => setInvest(!invest)}
              checked={invest}
            />
            <label
              htmlFor="demo-investCapital"
              className="custom-form-checkbox-label"
            >
              I want to invest capital
            </label>
          </div>
        </FormControl>
        <FormControl className="custom-form-group">
          <div className="custom-form-checkbox">
            <input
              id="demo-attractCapital"
              type="checkbox"
              className="custom-form-checkbox-input"
              onChange={e => setAttract(!attract)}
              checked={attract}
            />
            <label
              htmlFor="demo-attractCapital"
              className="custom-form-checkbox-label"
            >
              I want to attract capital
            </label>
          </div>
        </FormControl>

        <FormControl className="custom-form-group">
          <div className="custom-form-checkbox">
            <input
              id="demo-expertiseCapital"
              type="checkbox"
              className="custom-form-checkbox-input"
              onChange={e => setExpertise(!expertise)}
              checked={expertise}
            />
            <label
              htmlFor="demo-expertiseCapital"
              className="custom-form-checkbox-label"
            >
              I want to share expertise
            </label>
          </div>
        </FormControl>

        <Button
          variant="outlined"
          color="primary"
          fullWidth={true}
          className="events6ix-btn events6ix-btn-primary"
          onClick={demoRequest}
        >
          SEND MESSAGE
        </Button>
      </div>
    </React.Fragment>
  );
};

export default SixDemoSidebar;
