import React from "react";
import { QuoteIcon } from "../../../icons";

const SixCareersQuote = () => {
  return (
    <section className="events-page-landing-section events-page-landing-section-space events-page-careers-section-quote">
      <div className="events6ix-layout-page-middle">
        <div className="events-page-careers-section-quote-content">
          <div className="events-page-careers-section-quote-icon">
            <QuoteIcon />
          </div>
          <div className="events-page-careers-section-quote-text">
            At 6ix, we're not just building a platform; we're dismantling a
            financial caste system that has kept opportunities locked away from
            the many and in the hands of the few. For too long, your ability to
            invest, to raise capital, to have your voice heard in the financial
            world has been determined by who you know, where you're from, or how
            much money you started with. We're here to break those barriers and
            make capitalism work for everyone. Join us!
          </div>
          <div className="events-page-careers-section-quote-heading">
            Daniel Barankin, CEO of 6ix
          </div>
        </div>
      </div>
    </section>
  );
};

export default SixCareersQuote;
