import React, { useEffect, useRef, useState } from "react";
import { Box, Flex } from "@100mslive/roomkit-react";
import { UserIcon } from "../../icons";
import SEO from "../Common/SEO";
import FullPageProgress from "../FullPageProgress";
import { ToastManager } from "../Toast/ToastManager";
import { SESSION_AUTH } from "../../common/auth";
import { COMPANY_SERVICES } from "../../services";

const CompanyAbout = props => {
  const { orgId, orgName } = props;
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const dataFetchedRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const [managementList, setManagementList] = useState([]);
  const [orgAbout, setOrgAbout] = useState("");

  useEffect(() => {
    if (dataFetchedRef.current || !orgId) return;
    dataFetchedRef.current = true;
    setLoading(true);

    COMPANY_SERVICES.about({
      id: orgId,
      userId: isUserSession.userId,
      org: isUserSession?.org?.id,
    })
      .then(data => {
        if (data.code === 200) {
          setManagementList(data.data?.list ?? []);
          setOrgAbout(
            data.data?.orgAbout?.replace(/(?:\r\n|\r|\n)/g, "<br />")
          );
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          ToastManager.addToast({ title: data.message });
        }
      })
      .catch(err => {
        ToastManager.addToast({ title: err.message });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [orgId, isUserSession, LOGOUT_USER_SESSION]);

  return (
    <React.Fragment>
      <SEO title={`${orgName || "Profile"} | About on 6ix Events`} />

      {loading && <FullPageProgress />}
      {orgAbout && (
        <Flex className="featured-presenters  w-100" direction="column">
          <Box className="featured-presenters-heading">About</Box>
          <Box className="featured-presenters-text mt-0">
            <div
              dangerouslySetInnerHTML={{
                __html: orgAbout,
              }}
            ></div>
          </Box>
        </Flex>
      )}

      {orgAbout && managementList?.length > 0 ? (
        <div className="com-divider w-100"></div>
      ) : null}

      {managementList && managementList?.length > 0 ? (
        <Flex className="featured-presenters  w-100" direction="column">
          <Box className="featured-presenters-heading">Management</Box>

          {managementList?.map((listItem, key) => {
            return (
              <Box className="featured-presenters-single w-100" key={key}>
                <Flex>
                  <Box className="featured-presenters-img">
                    {listItem?.profileImage ? (
                      <img
                        src={listItem?.profileImage}
                        alt={`Management profile  ${listItem?.name}`}
                        width={142}
                        height={142}
                      />
                    ) : (
                      <UserIcon />
                    )}
                  </Box>

                  <Box className="w-100">
                    <Flex>
                      <Box>
                        <div className="featured-presenters-name">
                          {listItem?.name}
                        </div>
                        <div className="featured-presenters-profile">
                          {listItem?.jobTitle}{" "}
                          {listItem?.work && <> at {listItem?.work}</>}
                        </div>
                      </Box>

                      {listItem.companyLogo && (
                        <Box
                          className="featured-presenters-logo"
                          style={{
                            backgroundColor: listItem?.companyLogoColor,
                          }}
                        >
                          <img
                            src={listItem?.companyLogo}
                            alt="logo"
                            width={50}
                            height={50}
                          />
                        </Box>
                      )}
                    </Flex>

                    {listItem?.bio && (
                      <div className="featured-presenters-text">
                        {listItem?.bio}
                      </div>
                    )}
                  </Box>
                </Flex>
              </Box>
            );
          })}
        </Flex>
      ) : null}
    </React.Fragment>
  );
};

export default CompanyAbout;
